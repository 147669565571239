body {
  background-color: #f9f9f9;
}


/* Base container styling */
.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  max-width: 400px;
  background-color: #f9f9f9;
  box-sizing: border-box;
  padding: 20px;
  margin: 0 auto
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .login-input, .login-button {
    max-width: 90%; /* More screen-relative sizing */
  }
}

.login-title {
  font-size: 24px;
  margin-bottom: 20px;
  font-family: 'Arial', sans-serif; /* Improved typography */
}

.login-input {
  width: 100%;
  max-width: 400px;
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  transition: border-color 0.3s; /* Smooth transition for focus */
}

.login-input:focus {
  border-color: #4CAF50; /* Highlight focus */
}

.login-button {
  width: 100%;
  max-width: 400px;
  padding: 12px;
  border: none;
  background-color: #007BFF;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s; /* Smooth transition for hover */
}

.login-button:hover {
  background-color: #0056b3; /* Slightly darker on hover */
}

.login-error {
  color: red;
  margin-top: 10px;
  font-size: 14px;
}


/* Adding subtle shadow to inputs and button */
.login-input, .login-button {
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

/* Chatbot.css */
/* Responsive adjustments */
@media (max-width: 600px) {
  .chatbot {
    padding-top: 120px; /* More screen-relative sizing */
  }
}

.chatbot-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    max-width: 100%;
    background-color: #f9f9f9;
    font-family: 'Roboto', sans-serif; /* Updated font-family */
    box-sizing: border-box; /* Ensure padding and borders are included in the width and height */
    padding: 0 10px; /* Add some padding to the sides */
}

.chatbox {
    flex: 1;
    scroll-behavior: smooth;
    padding: 20px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    box-sizing: border-box; /* Ensure padding is included in the height */
    margin-bottom: 60px; /* Add bottom margin to ensure the last message is not hidden behind the input field */
    margin-top: 60px; /* Add bottom margin to ensure the last message is not hidden behind the input field */
}

.message {
    margin: 5px 0;
    padding: 10px;
    border-radius: 10px;
    max-width: 85%;
}

.user-message {
    align-self: flex-end;
    background-color: #e0e0e0; /* Light gray for user messages */
    color: black;
    font-weight: bold;
}

.bot-message {
    align-self: flex-start;
    background-color: white; /* White for bot messages */
    color: black;
}

.input-container {
    display: flex;
    padding: 10px;
    background-color: #f9f9f9;
    border-top: 1px solid #ddd;
    position: fixed;
    width: calc(100% - 20px); /* Adjust width to account for the padding */
    bottom: 0;
    z-index: 1;
    align-items: center;
    box-sizing: border-box; /* Ensure padding is included in the width */
    left: 10px; /* Adjust left position to account for the padding */
}

.input-container input {
    flex: 1;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 20px;
    margin-right: 10px;
    outline: none;
    color: #007BFF; /* Changed text color to a more prominent blue */
}
.input-container input::placeholder {
    font-size: 16px; /* Increased placeholder font size */
    font-weight: bold; /* Added font weight for prominence */
    color: #007BFF; /* Changed placeholder text color to match input text color */
}
.input-container button {
    padding: 10px;
    font-size: 16px;
    background-color: #4A90E2;
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    outline: none;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.input-container button:hover {
    background-color: #357ABD;
}

.typing-indicator {
    display: flex;
    justify-content: space-between;
    width: 30px;
    height: 10px;
    align-self: flex-start; /* Ensure the typing indicator is aligned with bot messages */
    margin-top: 5px; /* Add some margin at the top to separate from messages */
}

.typing-indicator div {
    background-color: #333;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    animation: pulse 1s infinite;
}

.typing-indicator div:nth-child(2) {
    animation-delay: 0.2s;
}

.typing-indicator div:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes pulse {
    0% { opacity: 0.5; }
    50% { opacity: 1; }
    100% { opacity: 0.5; }
}